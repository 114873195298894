<template >
    <div class="hcell" style="width: 687px;height: 433px;background-color: #f3f3f3" >
        <div v-if="param" >
            <div style="height: 365px;background-color: #f0f0f2;overflow: hidden;">
                <img  style="height:365px; width: 687px; "  :src="param.tb_image"  >
            </div>
            <div>
                <div style="line-height: 70px;font-size: 20px;font-weight: bold;"  >
                    <a class="mgd" @click="(param.tb_title_sub && param.tb_title_sub.len()>0)?goscheme(param.tb_title_sub):goto(param.tb_content)"  >{{param.tb_title}}</a></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            param: {
                type: Object,
                required: true,
            },
        },
        name:"cpblock_one",
        data(){
            return {
            }
        },
        created(){

        },
        methods:{
            goscheme(id) {
                this.$router.push({ path: 'scheme', query:{id:id} });
            },
            goto(url) {
                this.$router.push({ path: url, query: null });
            },
        },
        beforeDestroy(){

        },


    }
</script>

<style lang="css" scoped>
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }

</style>

