<template>
<div>
    <myhead :param="param" />
    <section>
        <div class="bannerzhfa">
            <div style="height:280px; "></div>
            <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

        </div>
    </section>
    <section style="background-color: #f3f3f3">
        <div class="htable" style="height: 64px;justify-content: center;align-items:center;font-size: 16px;">
            <div class="hcell" style="padding-right:42px;border-right: 1px solid rgba(20,20,20,0.7);font-weight: bold;">应用方案</div>
            <div v-if="lanterns && lanterns.length >0 " class="hcell" style="margin-left: 42px;"><span class="mgd" @click="goitem('lanterns')">路灯灯具</span></div>
            <div v-if="nationlant && nationlant.length >0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('nationlant')">民族特色灯具</span></div>
            <div  v-if="nationlamp && nationlamp.length >0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('nationlamp')">民族文化路灯</span></div>
        </div>
    </section>

    <section style="margin-top: 90px;" id="lanterns" v-if="lanterns && lanterns.length >0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">路灯</span>灯具</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_one :param="lanterns[0]"  />
                    <cpblock_one :param="lanterns[1]"  />
                    <cpblock_one :param="lanterns[2]"  />
                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>

        </div>
    </section>



    <section style="margin-top: 48px;" id="nationlant" v-if="nationlant && nationlant.length >0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">民族</span>特色灯具</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">

                    <cpblock_one :param="nationlant[0]"  />
                    <cpblock_one :param="nationlant[1]"  />
                    <cpblock_one :param="nationlant[2]"  />
                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>

        </div>
    </section>


    <section style="margin-top: 45px;" id="nationlamp"  v-if="nationlamp && nationlamp.length >0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">民族</span>文化路灯</div>
            </div>
            <div class="hcell" style="width: 1400px;">
                <div class="htable" style="justify-content: space-between;">
                    <scheme_one :param="nationlamp[0]"  />
                    <scheme_one :param="nationlamp[1]"  />

                </div>
                <div class="htable" style="justify-content: space-between;margin-top: 25px;" v-if="nationlamp[2]" >
                    <scheme_one :param="nationlamp[2]"  />
                    <scheme_one :param="nationlamp[3]"  />

                </div>

            </div>
            <div class="hcell" style="width: 130px;"></div>

        </div>
    </section>

    <section style="height: 90px;"></section>
</div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBases } from "../hts/api.js"
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    import scheme_one  from  '../components/scheme_one.vue'
    import mydata from '../static/data/zhscheme.json'

    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one,
            scheme_one,
        },
        data() {
            return {
                param,
                lanterns: mydata.lanterns,
                nationlant: mydata.nationlant,
                nationlamp: mydata.nationlamp,
            }
        },
        mounted() {
            // this.getList()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'zhscheme:[lanterns,nationlant,nationlamp]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.lanterns=resData.data.lanterns
                        this.nationlant=resData.data.nationlant
                        this.nationlamp=resData.data.nationlamp
                    })
                    .catch()
            },
            goitem(id){
                document.getElementById(id).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            },
            goscheme(id) {
                this.$router.push({ path: 'scheme', query:{id:id} });
            },
        },
    }
</script>

<style scoped>
    .bannerzhfa{
        background-image: url('~assets/banner24.png');
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }
</style>
